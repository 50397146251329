import {defineStore} from "pinia";

/**
 * @description: 用于存储标签页
 */
const tabsStore = defineStore("tabs", {
    state: () => ({
        // 标签页 默认值
        editableTabsValue: '/index',
        // 标签页数据
        editableTabs: [
            {
                // 标签页标题
                title: '首页',
                // url
                path: '/index',
                // 包含的菜单route信息
                router: {},
            }
        ],
    }),
    actions: {
        // 添加标签页
        addTab(menuItem) {
            // 添加标签页
            if (this.editableTabs.findIndex(item => item.path === menuItem.path) === -1) {
                this.editableTabs.push({
                    title: menuItem.title,
                    path: menuItem.path,
                    router: menuItem,
                });
            }
            // 默认选中标签页
            this.editableTabsValue = menuItem.path;
        },
        // 删除一个标签页
        removeTab(path) {
            let index = this.editableTabs.findIndex(item => item.path === path);
            if (index >= 0) {
                this.editableTabs.splice(index, 1);
            }
        },

    }

})


export default tabsStore