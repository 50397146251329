// storage前缀 本地存储  (防止一个域名出现串token问题)
let storagePrefix = process.env.VUE_APP_CACHE_PREFIX;
let storage = {
    /**
     * 最终在localStorage直接操作的key名字
     * @param key
     * @returns {*|string}
     */
    finalKey(key) {
        return key.indexOf(storagePrefix) === 0 ? key : storagePrefix + key;
    },

    /**
     * 获取原生key
     * @param key
     * @returns {string}
     */
    get(key) {
        return window.localStorage.getItem(this.finalKey(key))
    },


    /**
     * 获取key 使用JSON.parse处理
     * @param key
     * @returns {any}
     */
    getParse(key) {
        return JSON.parse(window.localStorage.getItem(this.finalKey(key)))
    },

    /**
     * 设置key 如果非string则JSON.stringify后存储
     * @param key
     * @param val
     */
    set(key, val) {
        window.localStorage.setItem(
            this.finalKey(key),
            typeof val !== 'string' ? JSON.stringify(val) : val
        )
    },

    /**
     * 删除key
     * @param key
     */
    del(key) {
        window.localStorage.removeItem(this.finalKey(key))
    },

    /**
     * 清空所有本系统key
     */
    clearAll() {
        let len = localStorage.length;  // 获取长度
        for (let i = 0; i < len; i++) {
            let key = window.localStorage.key(i);
            if (key && key.indexOf(storagePrefix) === 0) {
                this.del(key);
            }
        }
        // window.localStorage.window.localStorage.clear()
    }
}
export default storage
