<template>
  <router-view></router-view>
</template>


<style>

html,
body,
#app {
  width: 100%;
  height: 100%;
}

/*修复input选中没有蓝色的bug*/
.el-input__inner::selection {
  background-color: #318efe !important;
  color: #fff !important;
}

</style>
