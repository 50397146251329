import {createApp} from 'vue'
import App from './App.vue'
import axios from 'axios' // axios
import ElementPlus from "element-plus";
import 'element-plus/dist/index.css'
// 所有图标库
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 语言
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import aVue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';

import router from './router'

import store from './store/index'

import 'animate.css'

import './styles/common.scss';

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

// highlightjs
import hljs from 'highlight.js';

VMdPreview.use(githubTheme, {
    Hljs: hljs,
});

const app = createApp(App)

app.use(VMdPreview);

app.use(ElementPlus, {
    locale: zhCn,
    autoInsertSpace: false,
});
window.axios = axios;
app.use(aVue, {
    axios
});
app.use(router);
app.use(store);

// 图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}


app.mount('#app')
