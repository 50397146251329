import {defineStore} from "pinia";
import storage from "@/utils/storage";
import router from "@/router";
import dynamic from "@/router/openTreeToArray";
import tabsStore from "@/store/modules/tabs";


/**
 * @description: 用于用户数据
 */
const userStore = defineStore("user", {
    state: () => ({
        /**
         * token
         */
        token: storage.get("token"),
        /**
         * 用户信息
         */
        info: {},
        /**
         * 初始化路由标记
         */
        initRouter: false,
        /**
         * 路由
         */
        router: []
    }),
    actions: {
        /**
         * @description: 保存token
         * @param token
         */
        saveToken(token) {
            storage.set("token", token);
            this.token = token;
        },
        /**
         * @description: 保存用户信息 角色以及权限数据
         * @param data
         */
        saveUserInfoRolesPermissions(data) {
            this.info = data.user;
        },
        /**
         * @description: 设置路由
         */
        setRouter() {
            this.router = [
                {
                    "title": "首页",
                    "path": "/index",
                    "component": "Index",
                    "query": "",
                    "type": "menu",
                    "icon": "el-icon-home-filled", // <el-icon><HomeFilled /></el-icon>
                    "visible": 1,
                },
                {
                    "title": "商户信息",
                    "path": "/info",
                    "component": "Info",
                    "query": "",
                    "type": "menu",
                    "icon": "el-icon-user",
                    "visible": 1,
                },
                {
                    "title": "订单列表",
                    "path": "/order",
                    "component": "Order",
                    "query": "",
                    "type": "menu",
                    "icon": "el-icon-tickets",
                    "visible": 1,
                },
                {
                    "title": "提现管理",
                    "path": "/withdrawal",
                    "component": "Withdrawal",
                    "query": "",
                    "type": "menu",
                    "icon": "el-icon-connection",
                    "visible": 1,
                }, {
                    "title": "收支明细",
                    "path": "/payment",
                    "component": "Payment",
                    "query": "",
                    "type": "menu",
                    "icon": "el-icon-notebook",
                    "visible": 1,
                },
            ];


            // 动态配置路由
            dynamic.appendDynamicRoutes(this.router);
        },
        /**
         * @description: 退出登录
         */
        logout() {
            storage.clearAll();
            this.$reset();
            tabsStore().$reset();
            router.push({path: "/login", replace: true})
        },
    }

})


export default userStore
