<template>
  <div v-if="storeTabs.editableTabs.length>0" class="avue-tags" @click="contentMenu.flag=false">


    <!-- tag盒子 -->
    <div v-if="contentMenu.flag"
         :style="{left:contentMenu.x+'px',top:contentMenu.y+'px'}"
         class="avue-tags__contentmenu">
      <div class="item"
           @click="closeOtherTags">关闭其他
      </div>
      <div class="item"
           @click="closeAllTags">关闭全部
      </div>
    </div>


    <div class="avue-tags__box">

      <el-tabs v-model="storeTabs.editableTabsValue"
               :closable="storeTabs.editableTabs.length!==1"
               style="width: 100%"
               type="card"
               @contextmenu="handleContextmenu"
               @edit="menuTag" @tab-click="clickTag">
        <el-tab-pane v-for="(item,index) in storeTabs.editableTabs"
                     :key="index"
                     :label="item.title"
                     :name="item.path">
          <template #label>
            <span>
              {{ item.title }}
            </span>
          </template>

        </el-tab-pane>
      </el-tabs>
    </div>

  </div>
</template>


<script setup>
import pinia from "@/store/index";
import {onMounted, ref, watch} from "vue";
import {useRouter} from "vue-router";
import tabsStore from "@/store/modules/tabs";

const storeTabs = tabsStore(pinia);

// 右击菜单
const contentMenu = ref({
  x: "",
  y: "",
  flag: false
});

const router = useRouter();

// 点击tab事件
const clickTag = (item) => {
  let path = item.props.name
  router.push(path)
};

// 标签操作-只处理删除的
const menuTag = (path, action) => {
  if (action === "remove") {
    let {key} = findTag(path);
    storeTabs.removeTab(path);
    storeTabs.editableTabsValue = storeTabs.editableTabs[key - 1].path;
    router.push(storeTabs.editableTabsValue)
  }
};


// 查找tab
const findTag = (path) => {
  let tag = storeTabs.editableTabs.find(item => item.path === path);
  let key = storeTabs.editableTabs.findIndex(item => item.path === path);
  return {tag, key}
};


// 悬浮关闭窗口
const handleContextmenu = (event) => {
  let target = event.target;
  let flag = false;
  if (target.className.indexOf("el-tabs__item") > -1) flag = true;
  else if (target.parentNode.className.indexOf("el-tabs__item") > -1) {
    // target = target.parentNode;
    flag = true;
  }
  if (flag) {
    event.preventDefault();
    event.stopPropagation();
    contentMenu.value.x = event.clientX;
    contentMenu.value.y = event.clientY;
    contentMenu.value.flag = true;
  }
};


// 关闭其他
const closeOtherTags = () => {
  storeTabs.editableTabs = storeTabs.editableTabs.filter(item => item.path === storeTabs.editableTabsValue || item.path === "/index");
  contentMenu.value.flag = false;
};

// 关闭全部
const closeAllTags = () => {
  contentMenu.value.flag = false;
  storeTabs.$reset();
  router.push(storeTabs.editableTabsValue)
}

onMounted(() => {
  storeTabs.addTab({
    title: router.currentRoute.value.name,
    path: router.currentRoute.value.path
  });
});


/**
 * 监听路由变化
 */
watch(router.currentRoute, (to) => {
  storeTabs.editableTabsValue = to.path;
});


</script>

